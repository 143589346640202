<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form ref="form">
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="BookIcon"
            size="25"
            class="mr-2"
          />
          <h1>Nuovo Contatto</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Nominativo Contatto *"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="contactData.display_name"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Azienda"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="contactData.company"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Email Contatto *"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="contactData.email"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Telefono Contatto"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-model="contactData.phone_number"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Associa a un Utente"
            label-for="user"
          >
            <v-select
              id="user"
              :filterable="false"
              :options="usersPaginated"
              @open="onOpen"
              @close="onClose"
              @search="(query) => (search = query)"
              @input="selectUser($event)"
            >
              <template #list-footer>
                <li
                  v-show="hasNextPage"
                  ref="load"
                  class="loader"
                >
                  Carica più Utenti...
                </li>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="addContact"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
            />
            <span v-if="!loading">Aggiungi</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BLink, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import userStoreModule from '@/views/apps/user/userStoreModule'
import { useToast } from 'vue-toastification/composition'
import contactsStoreModule from '../contactsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BLink,
    BSpinner,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const CONTACTS_APP_STORE_MODULE_NAME = 'app-contacts'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(CONTACTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(CONTACTS_APP_STORE_MODULE_NAME, contactsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACTS_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTACTS_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000 })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    return {
      users,
      observer: null,
      limit: 10,
      search: '',
      contactData: {
        display_name: null,
        company: null,
        email: null,
        phone_number: null,
        user_id: null,
      },
      loading: false,
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    selectUser(e) {
      this.contactData.user_id = e ? e.value : null
    },
    goBack() {
      this.$router.go(-1)
    },
    addContact() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      store
        .dispatch('app-contacts/addContact', this.contactData)
        .then(result => {
          this.$router.replace({ name: 'apps-contacts-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Contatto #${result.data.id} aggiunto con successo`,
                icon: 'BookIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.contactData.display_name) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Nome',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Nome del Contatto per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.contactData.email) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Email',
            icon: 'AlertTriangleIcon',
            text: 'Inserire Email del Contatto per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
